<template>
  <Card class="mt-md-15" style="margin-bottom: 200px">
    <v-card-title>Iniciar sesión </v-card-title>
    <v-card-text>
      <div class="fields mx-auto">
        <v-text-field
          v-model="email"
          solo
          label="Correo"
          type="email"
          prepend-inner-icon="mdi-email"
          v-on:keyup.enter="login"
        ></v-text-field>
        <v-text-field
          v-model="password"
          solo
          label="Contraseña"
          type="password"
          prepend-inner-icon="mdi-security"
          v-on:keyup.enter="login"
        ></v-text-field>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2 mb-2 send"
        :loading="loading"
        :disabled="loading"
        color="#ffffff"
        @click="login"
      >
        Entrar
        <template v-slot:loader>
          <span>Iniciando</span>
        </template>
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" :timeout="5000">
      {{ text }}
    </v-snackbar>
  </Card>
</template>
<script>
import Card from "@/components/card.vue";
export default {
  name: "Login",
  components: {
    Card,
  },
  data: () => ({
    email: "",
    password: "",
    loading: false,
  }),
  created() {
    document.title = "Indutec Bahía";
  },
  methods: {
    login() {
      this.$store.dispatch("login", {
        email: this.email,
        password: this.password,
      });
    },
  },
  computed: {
    snackbar: {
      get: function () {
        return !!this.$store.state.error;
      },
      set: function (val) {
        this.$store.dispatch("setError", "");
        return val;
      },
    },
    text() {
      switch (this.$store.state.error) {
        case "auth/invalid-email":
          return "Debe ser un correo válido";
        case "auth/wrong-password":
          return "La contraseña o el usuario es incorrecto";
        case "auth/user-not-found":
          return "El usuario no existe";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.send {
  color: black !important;
}
</style>